import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumMessageType } from '../../../../enums';
import { GQL_MUTATION_SET_ACCOUNT_OPERATION_DUPLICATION } from '../../../../graphql/Mutations';
import { IAccountOperation } from '../../../../interfaces';
import { addMessage } from '../../../messages/Message';
import { setAllRefetchNeeded } from '../../../../redux/store';
import 'dayjs/locale/fr';
import AutorenewIcon from '@mui/icons-material/Autorenew';

interface IProps {
    open: boolean;
    handleClose: () => void;
    accountOperation: IAccountOperation

}

export const ModalToDuplicateAccountOperation: FC<IProps> = (props) => {
    const [toDuplicate, setToDuplicate] = useState(props.accountOperation.toDuplicate)
    const [errorOnToDuplicate, setErrorOnToDuplicate] = useState(false)
    const [setAccountOperationDuplication, { data: setAccountOperationDuplicationData, loading: setAccountOperationDuplicationLoading, error: setAccountOperationDuplicationError }] = useMutation(GQL_MUTATION_SET_ACCOUNT_OPERATION_DUPLICATION)
    const theme = useTheme()

    const resetDataFromProps = () => {
        setToDuplicate(props.accountOperation.toDuplicate)
    }

    // Après un update il faut faire çà sinon les données ne sont pas rafraichies
    useEffect(() => {
        resetDataFromProps()
    }, [props.accountOperation])

    useEffect(() => {
        if (setAccountOperationDuplicationError) {
            addMessage({
                location: "ModalToDuplicateAccountOperation",
                type: EnumMessageType.Error,
                message: setAccountOperationDuplicationError.message,
            })
        } else if (setAccountOperationDuplicationData) {
            if (setAccountOperationDuplicationData.setAccountOperationDuplication.statusCode === 200) {
                setAllRefetchNeeded("ModalToDuplicateAccountOperation")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Duplication paramétrée.",
                })
                handleClose()
            } else {
                setAccountOperationDuplicationData.setAccountOperationDuplication.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setAccountOperationDuplicationData, setAccountOperationDuplicationError])

    const checkErrors = () => {
        let res = true
        if (toDuplicate === undefined || isNaN(toDuplicate)) {
            setErrorOnToDuplicate(true)
            addMessage({
                type: EnumMessageType.Error,
                message: "Le nombre de mois est obligatoire.",
            })
            res = false
        }
        return res
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            setAccountOperationDuplication({
                variables: {
                    accountOperationId: props.accountOperation.id,
                    toDuplicate: toDuplicate,
                }
            })
        }
    }

    const handleClose = () => {
        resetDataFromProps()
        props.handleClose()
    }

    const handleDeactivateDuplication = () => {
        setAccountOperationDuplication({
            variables: {
                accountOperationId: props.accountOperation.id,
                toDuplicate: 0,
            }
        })
        handleClose()
    }


    return (
        <div className="ModalToDuplicateAccountOperation">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>


                            <AutorenewIcon sx={{
                                fontSize: 24,
                                mr: "12px",
                                color: theme.palette.primary.main,
                            }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Paramétrage de duplication
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}>
                        <FormControl variant="outlined" sx={{
                            mt: 2,
                        }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Nombre de mois</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                type='number'
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         €
                                //     </InputAdornment>
                                // }
                                label="Nombre de mois"
                                color="primary"
                                onChange={(event) => { setToDuplicate(parseInt(event.currentTarget.value)) }}
                                value={toDuplicate}
                                autoComplete='off'
                                error={errorOnToDuplicate}
                                sx={{
                                    width: "264px",
                                }} />
                        </FormControl>
                        <Typography>
                            Cette opérations sera dupliquée tous les {toDuplicate} mois.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button onClick={handleDeactivateDuplication} variant="contained" color="secondary">Arrêter la duplication</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
